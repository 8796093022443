import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { Cognito, signHttpRequest } from '@xbcb/aws-utils';
import { getEnv } from '@xbcb/ui-utils';
import { getGuestUserCredentialsProvider } from './getGuestUserCredentialsProvider';
import { isLoginProtectedQuery } from './isLoginProtectedQuery';
import { HttpLink } from '@apollo/client';
import { isAglPortal, isMonsEnv } from '@xbcb/ui-env';

const AGL_ROUTE_PREFIX = '/cbms';

interface Options {
  body: string;
  headers: Record<string, string>;
  method: string;
}

const { gateway } = getEnv();
const { endpoint, path, region } = gateway;
let provider;
const cbmsFetch = async (uri: string, options: Options) => {
  provider = Cognito.credentialProvider;

  const mustBeLoggedIn = isLoginProtectedQuery(options.body);
  if (mustBeLoggedIn) {
    // If the user must be logged in and they're not (authUser is false)
    // then we cannot proceed, throw an error
    if (!(await Cognito.authUser())) {
      throw new Error('User is not logged in');
    }
    // If the user must be logged in and there are not any credentials
    // found, then we cannot proceed, throw an error. Otherwise we risk
    // proceeding with guest credentials despite the user being required
    // to be logged in
    if (!provider) {
      throw new Error('User does not have access');
    }
  } else {
    if (!provider) {
      provider = await getGuestUserCredentialsProvider(region);
    }
  }
  const { UI_API_KEY } = getEnv();
  // At this point we know the user is allowed to make the fetch (they
  // either must be logged in, they are, and they have credentials _or_
  // they don't have to be logged in) otherwise we would have thrown an
  // error above. Thus, default to using the awsConfigCredentials as we
  // want to use them if they user is logged in. Otherwise fallback to
  // guest credentials, this must mean the user is not required to be
  // logged in and isn't

  const oldApolloServer = 'apollo-server-stable';
  // TODO support mock graphql schema
  const headers: Record<string, string> = {
    'x-api-key': UI_API_KEY || '',
    'apollo-server': options.headers?.['apollo-server'] || oldApolloServer,
    ...(options.headers['active-broker-id'] && {
      'active-broker-id': options.headers['active-broker-id'],
    }),
  };

  const signedRequest = await signHttpRequest({
    method: options.method,
    uri,
    headers,
    credentials: provider,
    body: options.body,
    region,
  });

  return fetch(uri, {
    method: signedRequest.method,
    headers: signedRequest.headers,
    body: signedRequest.body,
  });
};

// We don't sign requests to the gateway when behind Mons
const isMons = isMonsEnv();
// At the AGL portal our home page is /cbms, we need to add that to the query endpoint
// so that our Mons routing picks it up.
const isAgl = isAglPortal();
const routePrefix = isAgl ? AGL_ROUTE_PREFIX : '';

const fetchFunction = isMons ? fetch : cbmsFetch;

// TODO after SSO is pushed to prod, move the mons url to ui-env instead of window.location.origin
const uri = isMons
  ? window.location.origin + routePrefix + path
  : endpoint + path;

const httpOptions = {
  fetch: fetchFunction,
  uri,
  // When behind Mons, requests to the Gateway are same-origin
  fetchOptions: isMons ? { mode: 'same-origin' } : { mode: 'cors' },
  headers: isAgl ? { 'client-tenant-id': window.location.host } : undefined,
};

export const batchHttpLink = new BatchHttpLink({
  ...httpOptions,
  batchMax: 10,
});

export const httpLink = new HttpLink(httpOptions);
