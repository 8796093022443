import { S3Uploader, S3RecordSaver } from '@xbcb/record-dao';
import { gzipJson, gunzipJson } from '@xbcb/js-utils';

import {
  LargeEntrySavedFields,
  UsConsumptionEntryMutationInput,
} from './types';

// Exported from here so that we can track the compression / decompression from one place
/** The function to use to decompress any fields uploaded by a UsConsumptionEntryS3Saver */
export const usConsumptionEntryS3SaverDecompressionFunction = gunzipJson;

/** A S3RecordSaver that will save invoices off of a UsConsumptionEntryMutationInput */
export class UsConsumptionEntryS3Saver extends S3RecordSaver<
  LargeEntrySavedFields,
  UsConsumptionEntryMutationInput
> {
  constructor(s3Uploader: S3Uploader) {
    super(['invoices'], s3Uploader, gzipJson);
  }
}
