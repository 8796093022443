import { UiStage } from '@xbcb/ui-types';
import {
  environmentVariables,
  EnvironmentVariables,
} from './customDomainRuntimeInfo';
import { gatewayEndpoints } from './gatewayEndpoints';

export interface BaseEnv {
  stage: UiStage;
  version: string;
  UI_API_KEY?: string;
  gateway: {
    endpoint: string;
    path: string;
    region: string;
  };
  GUEST_COGNITO_IDENTITY_POOL_ID: string;
}

export const getEnv = <T extends EnvironmentVariables = EnvironmentVariables>(
  env = process.env,
): BaseEnv & T => ({
  stage: env.REACT_APP_STAGE as UiStage,
  version: env.REACT_APP_VERSION as string,
  UI_API_KEY: env.REACT_APP_UI_API_KEY,
  gateway: gatewayEndpoints[env.REACT_APP_STAGE as UiStage],
  GUEST_COGNITO_IDENTITY_POOL_ID:
    env.REACT_APP_GUEST_COGNITO_IDENTITY_POOL_ID as string,
  ...(environmentVariables as T),
});
