export const workOrderMilestoneNameDescription = 'The name of the milestone';
export enum WorkOrderMilestoneName {
  WORK_ORDER_READY = 'WORK_ORDER_READY',
  WORK_ORDER_ASSIGNED = 'WORK_ORDER_ASSIGNED',
  WORK_ORDER_BLOCKED = 'WORK_ORDER_BLOCKED',
  WORK_ORDER_PENDING_INQUIRY = 'WORK_ORDER_PENDING_INQUIRY',
  WORK_ORDER_INQUIRY_RESOLVED = 'WORK_ORDER_INQUIRY_RESOLVED',
  HOT_WORK_ORDER_REQUESTED = 'HOT_WORK_ORDER_REQUESTED',
  WORK_ORDER_COMPLETE = 'WORK_ORDER_COMPLETE',
  ESCALATED_TO_TEAM_LEAD = 'ESCALATED_TO_TEAM_LEAD',
  ESCALATED_TO_SME = 'ESCALATED_TO_SME',
  ESCALATION_RESOLVED = 'ESCALATION_RESOLVED',
  DOCUMENT_EXTRACTION_REQUESTED = 'DOCUMENT_EXTRACTION_REQUESTED',
  DOCUMENT_EXTRACTION_COMPLETE = 'DOCUMENT_EXTRACTION_COMPLETE',

  US_ISF_CREATED = 'US_ISF_CREATED',
  US_ISF_SUBMITTED = 'US_ISF_SUBMITTED',
  US_ISF_REJECTED = 'US_ISF_REJECTED',
  US_ISF_FILED = 'US_ISF_FILED',
  US_ISF_DELETED = 'US_ISF_DELETED',
  US_ISF_SKIPPED = 'US_ISF_SKIPPED',
  US_ISF_BILL_MATCH = 'US_ISF_BILL_MATCH',
  US_ISF_NO_BILL_MATCH_NOT_ON_FILE = 'US_ISF_NO_BILL_MATCH_NOT_ON_FILE',

  // Milestone published to show ISF is auto updated or not
  US_ISF_AUTO_UPDATE_SUCCEEDED = 'US_ISF_AUTO_UPDATE_SUCCEEDED',
  US_ISF_NOT_AUTO_UPDATED_BECAUSE_VESSEL_ARRIVED = 'US_ISF_NOT_AUTO_UPDATED_BECAUSE_VESSEL_ARRIVED',

  // Bond bot uses these <WorkOrder>_BOND_ACQUISITION_<X> and US_ISF_BOND_CLOSURE_<X> milestones
  US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_REQUESTED = 'US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_REQUESTED',
  US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_SUCCEEDED = 'US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_SUCCEEDED',
  US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_FAILED = 'US_CONSUMPTION_ENTRY_SINGLE_ENTRY_BOND_ACQUISITION_FAILED',

  US_ISF_BOND_ACQUISITION_REQUESTED = 'US_ISF_BOND_ACQUISITION_REQUESTED',
  US_ISF_BOND_ACQUISITION_SUCCEEDED = 'US_ISF_BOND_ACQUISITION_SUCCEEDED',
  US_ISF_BOND_ACQUISITION_FAILED = 'US_ISF_BOND_ACQUISITION_FAILED',

  US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_REQUESTED = 'US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_REQUESTED',
  US_IOR_CONTINUOUS_BOND_REQUEST_PENDING_REVIEW = 'US_IOR_CONTINUOUS_BOND_REQUEST_PENDING_REVIEW',
  US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_SUCCEEDED = 'US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_SUCCEEDED',
  US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_FAILED = 'US_IOR_CONTINUOUS_BOND_REQUEST_ACQUISITION_FAILED',

  US_ISF_BOND_CLOSURE_REQUESTED = 'US_ISF_BOND_CLOSURE_REQUESTED',
  US_ISF_BOND_CLOSURE_SUCCEEDED = 'US_ISF_BOND_CLOSURE_SUCCEEDED',
  US_ISF_BOND_CLOSURE_FAILED = 'US_ISF_BOND_CLOSURE_FAILED',

  // Milestone published ony when bill is found in AMS
  AMS_QUERY_SUCCESS = 'AMS_QUERY_SUCCESS',

  // AX message contains some warnings
  US_CONSUMPTION_ENTRY_CENSUS_WARNINGS = 'US_CONSUMPTION_ENTRY_CENSUS_WARNINGS',
  US_CONSUMPTION_ENTRY_CENSUS_WARNINGS_RESOLVED = 'US_CONSUMPTION_ENTRY_CENSUS_WARNINGS_RESOLVED',
  US_CONSUMPTION_ENTRY_UFLPA_WARNING = 'US_CONSUMPTION_ENTRY_UFLPA_WARNING',
  US_CONSUMPTION_ENTRY_CREATED = 'US_CONSUMPTION_ENTRY_CREATED',
  US_CONSUMPTION_ENTRY_SUBMITTED = 'US_CONSUMPTION_ENTRY_SUBMITTED',
  US_CONSUMPTION_ENTRY_REJECTED = 'US_CONSUMPTION_ENTRY_REJECTED',
  US_CONSUMPTION_ENTRY_FILED = 'US_CONSUMPTION_ENTRY_FILED',
  US_CONSUMPTION_ENTRY_DELETED = 'US_CONSUMPTION_ENTRY_DELETED',
  US_CONSUMPTION_ENTRY_SKIPPED = 'US_CONSUMPTION_ENTRY_SKIPPED',

  US_CONSUMPTION_ENTRY_DUTY_CONFIRMATION_REQUESTED = 'US_CONSUMPTION_ENTRY_DUTY_CONFIRMATION_REQUESTED',
  US_CONSUMPTION_ENTRY_DUTY_CONFIRMED = 'US_CONSUMPTION_ENTRY_DUTY_CONFIRMED',
  US_CONSUMPTION_ENTRY_DUTY_DISPUTED = 'US_CONSUMPTION_ENTRY_DUTY_DISPUTED',

  // TODO discuss more granular milestones with OPS
  US_CONSUMPTION_ENTRY_CBP_RELEASE = 'US_CONSUMPTION_ENTRY_CBP_RELEASE',
  US_CONSUMPTION_ENTRY_CBP_HOLD = 'US_CONSUMPTION_ENTRY_CBP_HOLD',
  US_CONSUMPTION_ENTRY_CBP_EXAM = 'US_CONSUMPTION_ENTRY_CBP_EXAM',
  US_CONSUMPTION_ENTRY_CBP_NOTIFICATION = 'US_CONSUMPTION_ENTRY_CBP_NOTIFICATION', // UC message

  US_CONSUMPTION_ENTRY_CLEARED_FOR_PICKUP = 'US_CONSUMPTION_ENTRY_CLEARED_FOR_PICKUP',

  US_CONSUMPTION_ENTRY_PGA_RELEASE = 'US_CONSUMPTION_ENTRY_PGA_RELEASE',
  US_CONSUMPTION_ENTRY_PGA_HOLD = 'US_CONSUMPTION_ENTRY_PGA_HOLD',
  US_CONSUMPTION_ENTRY_PGA_REJECTED = 'US_CONSUMPTION_ENTRY_PGA_REJECTED',
  US_CONSUMPTION_ENTRY_PGA_MAY_PROCEED_RESCINDED = 'US_CONSUMPTION_ENTRY_PGA_MAY_PROCEED_RESCINDED',

  //  Indicates that the merchandise has arrived,  all data required for entry has been submitted to the U.S. Government including PGAs. (All Released)
  US_CONSUMPTION_ENTRY_ONE_USG = 'US_CONSUMPTION_ENTRY_ONE_USG',

  US_CONSUMPTION_ENTRY_PAYMENT_AUTHORIZED = 'US_CONSUMPTION_ENTRY_PAYMENT_AUTHORIZED',
  US_CONSUMPTION_ENTRY_PAYMENT_FAILURE = 'US_CONSUMPTION_ENTRY_PAYMENT_FAILURE',
  US_CONSUMPTION_ENTRY_DAILY_STATEMENT_FINALIZED = 'US_CONSUMPTION_ENTRY_DAILY_STATEMENT_FINALIZED',
  US_CONSUMPTION_ENTRY_MONTHLY_STATEMENT_FINALIZED = 'US_CONSUMPTION_ENTRY_MONTHLY_STATEMENT_FINALIZED',
  US_CONSUMPTION_ENTRY_LIQUIDATED = 'US_CONSUMPTION_ENTRY_LIQUIDATED',
  US_CONSUMPTION_ENTRY_RE_LIQUIDATED = 'US_CONSUMPTION_ENTRY_RE_LIQUIDATED',
  US_CONSUMPTION_ENTRY_LIQUIDATION_EXTENDED = 'US_CONSUMPTION_ENTRY_LIQUIDATION_EXTENDED',
  US_CONSUMPTION_ENTRY_LIQUIDATION_SUSPENDED = 'US_CONSUMPTION_ENTRY_LIQUIDATION_SUSPENDED',
  US_CONSUMPTION_ENTRY_STARTED = 'US_CONSUMPTION_ENTRY_STARTED',

  US_CONSUMPTION_ENTRY_DAILY_STATEMENT_NUMBER_ADDED = 'US_CONSUMPTION_ENTRY_DAILY_STATEMENT_NUMBER_ADDED',
  US_CONSUMPTION_ENTRY_DAILY_STATEMENT_NUMBER_REMOVED = 'US_CONSUMPTION_ENTRY_DAILY_STATEMENT_NUMBER_REMOVED',
  US_CONSUMPTION_ENTRY_DAILY_STATEMENT_AUTHORIZED = 'US_CONSUMPTION_ENTRY_DAILY_STATEMENT_AUTHORIZED',
  US_CONSUMPTION_ENTRY_DAILY_STATEMENT_FAILURE = 'US_CONSUMPTION_ENTRY_DAILY_STATEMENT_FAILURE',
  US_CONSUMPTION_ENTRY_STATEMENT_DATE_CALCULATION_ERROR = 'US_CONSUMPTION_ENTRY_STATEMENT_DATE_CALCULATION_ERROR',

  US_CONSUMPTION_ENTRY_STATEMENT_PAID = 'US_CONSUMPTION_ENTRY_STATEMENT_PAID',

  US_CONSUMPTION_ENTRY_PREPAID_INVOICE_CREATED = 'US_CONSUMPTION_ENTRY_PREPAID_INVOICE_CREATED',
  US_CONSUMPTION_ENTRY_PREPAID_INVOICE_PAID = 'US_CONSUMPTION_ENTRY_PREPAID_INVOICE_PAID',

  US_CONSUMPTION_ENTRY_PAYMENT_MISSED_FIRST_STRIKE = 'US_CONSUMPTION_ENTRY_PAYMENT_MISSED_FIRST_STRIKE',
  US_CONSUMPTION_ENTRY_PAYMENT_MISSED_SECOND_STRIKE = 'US_CONSUMPTION_ENTRY_PAYMENT_MISSED_SECOND_STRIKE',
  US_CONSUMPTION_ENTRY_PAYMENT_MISSED_ACTIONED = 'US_CONSUMPTION_ENTRY_PAYMENT_MISSED_ACTIONED',
  US_CONSUMPTION_ENTRY_PMS_PAYMENT_MISSED_ACTIONED = 'US_CONSUMPTION_ENTRY_PMS_PAYMENT_MISSED_ACTIONED',

  US_POST_SUMMARY_CORRECTION_CREATED = 'US_POST_SUMMARY_CORRECTION_CREATED',
  US_POST_SUMMARY_CORRECTION_SUBMITTED = 'US_POST_SUMMARY_CORRECTION_SUBMITTED',
  US_POST_SUMMARY_CORRECTION_REJECTED = 'US_POST_SUMMARY_CORRECTION_REJECTED',
  US_POST_SUMMARY_CORRECTION_FILED = 'US_POST_SUMMARY_CORRECTION_FILED',

  // US_POST_SUMMARY_CORRECTION_DUTY_CONFIRMATION_REQUESTED = 'US_POST_SUMMARY_CORRECTION_DUTY_CONFIRMATION_REQUESTED',
  // US_POST_SUMMARY_CORRECTION_DUTY_CONFIRMED = 'US_POST_SUMMARY_CORRECTION_DUTY_CONFIRMED',
  // US_POST_SUMMARY_CORRECTION_DUTY_DISPUTED = 'US_POST_SUMMARY_CORRECTION_DUTY_DISPUTED',

  // TODO discuss more granular milestones with OPS
  US_POST_SUMMARY_CORRECTION_CBP_NOTIFICATION = 'US_POST_SUMMARY_CORRECTION_CBP_NOTIFICATION', // UC message

  US_POST_SUMMARY_CORRECTION_LIQUIDATED = 'US_POST_SUMMARY_CORRECTION_LIQUIDATED',
  US_POST_SUMMARY_CORRECTION_RE_LIQUIDATED = 'US_POST_SUMMARY_CORRECTION_RE_LIQUIDATED',
  US_POST_SUMMARY_CORRECTION_LIQUIDATION_EXTENDED = 'US_POST_SUMMARY_CORRECTION_LIQUIDATION_EXTENDED',
  US_POST_SUMMARY_CORRECTION_LIQUIDATION_SUSPENDED = 'US_POST_SUMMARY_CORRECTION_LIQUIDATION_SUSPENDED',
  US_POST_SUMMARY_CORRECTION_STARTED = 'US_POST_SUMMARY_CORRECTION_STARTED',

  // TODO define each of these as we implement them
  US_IN_BOND_CREATED = 'US_IN_BOND_CREATED',
  US_IN_BOND_SUBMITTED = 'US_IN_BOND_SUBMITTED',
  US_IN_BOND_AUTHORIZED = 'US_IN_BOND_AUTHORIZED',
  US_IN_BOND_ARRIVED = 'US_IN_BOND_ARRIVED',
  US_IN_BOND_BILL_ARRIVED = 'US_IN_BOND_BILL_ARRIVED',
  US_IN_BOND_CONTAINER_ARRIVED = 'US_IN_BOND_CONTAINER_ARRIVED',
  US_IN_BOND_EXPORTED = 'US_IN_BOND_EXPORTED',
  US_IN_BOND_BILL_EXPORTED = 'US_IN_BOND_BILL_EXPORTED',
  US_IN_BOND_CONTAINER_EXPORTED = 'US_IN_BOND_CONTAINER_EXPORTED',
  US_IN_BOND_TRANSFERRED = 'US_IN_BOND_TRANSFERRED',
  US_IN_BOND_DIVERSION = 'US_IN_BOND_DIVERSION',
  US_IN_BOND_DELETED = 'US_IN_BOND_DELETED',
  US_IN_BOND_REJECTED = 'US_IN_BOND_REJECTED',
  US_IN_BOND_CLOSED = 'US_IN_BOND_CLOSED',

  US_DRAWBACK_CREATED = 'US_DRAWBACK_CREATED',
  US_DRAWBACK_SUBMITTED = 'US_DRAWBACK_SUBMITTED',
  US_DRAWBACK_REJECTED = 'US_DRAWBACK_REJECTED',
  US_DRAWBACK_FILED = 'US_DRAWBACK_FILED',
  US_DRAWBACK_DELETED = 'US_DRAWBACK_DELETED',

  DIS_SUBMITTED = 'DIS_SUBMITTED',

  // Created after the user clicks this button in the UI
  US_IOR_CONTINUOUS_BOND_REQUEST_CHARGE_BOND_REQUESTED = 'US_IOR_CONTINUOUS_BOND_REQUEST_CHARGE_BOND_REQUESTED',
  // Created if we fail to charge bond
  US_IOR_CONTINUOUS_BOND_REQUEST_CHARGE_BOND_FAILED = 'US_IOR_CONTINUOUS_BOND_REQUEST_CHARGE_BOND_FAILED',
  // Created if we succeed to charge bond
  US_IOR_CONTINUOUS_BOND_REQUEST_CHARGE_BOND_SUCCEEDED = 'US_IOR_CONTINUOUS_BOND_REQUEST_CHARGE_BOND_SUCCEEDED',
  // Created one we attempt to charge the renewal
  US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_ATTEMPTED = 'US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_ATTEMPTED',
  // Created if the renewal failed, for whatever reason
  US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_FAILED = 'US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_FAILED',
  // Created if the renewal succeeded and we successfully invoiced the customer
  US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_SUCCEEDED = 'US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_SUCCEEDED',
  // Created if the user cancels the renewal (via the button from the UI)
  US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_CANCELED = 'US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_CANCELED',
  // TODO determine whether we are going to offer the ability to resume
  // Created if the user "uncancled" (resumed) the renewal process (via the button from the UI)
  // US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_RESUMED = 'US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_RESUMED',

  // IOR Onboarding (UsIorActivation) Milestones
  US_IOR_ACTIVATION_MASTER_POWER_OF_ATTORNEY_SIGNED = 'US_IOR_ACTIVATION_MASTER_POWER_OF_ATTORNEY_SIGNED',
  US_IOR_ACTIVATION_SUB_POWER_OF_ATTORNEY_SIGNED = 'US_IOR_ACTIVATION_SUB_POWER_OF_ATTORNEY_SIGNED',
  US_IOR_ACTIVATION_DIRECT_POWER_OF_ATTORNEY_SIGNED = 'US_IOR_ACTIVATION_DIRECT_POWER_OF_ATTORNEY_SIGNED',
  US_IOR_ACTIVATION_CORPORATE_CERTIFICATION_SIGNED = 'US_IOR_ACTIVATION_CORPORATE_CERTIFICATION_SIGNED',
  US_IOR_ACTIVATION_IOR_ACTIVATED = 'US_IOR_ACTIVATION_IOR_ACTIVATED',

  // Delivery Order Milestones
  DELIVERY_ORDER_CREATED = 'DELIVERY_ORDER_CREATED',
  DELIVERY_ORDER_ISSUED = 'DELIVERY_ORDER_ISSUED',

  CUSTOMS_ENTRY_CLEARED_FOR_PICKUP = 'CUSTOMS_ENTRY_CLEARED_FOR_PICKUP', // EU/UK Customs cleared for pickup
  CUSTOMS_ENTRY_DUTY_CONFIRMATION_REQUESTED = 'CUSTOMS_ENTRY_DUTY_CONFIRMATION_REQUESTED',
  CUSTOMS_ENTRY_DUTY_CONFIRMED = 'CUSTOMS_ENTRY_DUTY_CONFIRMED',
  CUSTOMS_ENTRY_DUTY_DISPUTED = 'CUSTOMS_ENTRY_DUTY_DISPUTED',
  CUSTOMS_ENTRY_STARTED = 'CUSTOMS_ENTRY_STARTED',
  CUSTOMS_ENTRY_FILED = 'CUSTOMS_ENTRY_FILED',
  CUSTOMS_ENTRY_DELETED = 'CUSTOMS_ENTRY_DELETED',
  CUSTOMS_ENTRY_RELEASE = 'CUSTOMS_ENTRY_RELEASE',
  //  Indicates that the merchandise has arrived,  all data required for entry has been submitted to the Customs Authority. (All Released)
  CUSTOMS_ENTRY_ONE_USG = 'CUSTOMS_ENTRY_ONE_USG',
  CUSTOMS_ENTRY_PAYMENT_BLOCKED = 'CUSTOMS_ENTRY_PAYMENT_BLOCKED',
  // Broker has submitted their invoice
  CUSTOMS_ENTRY_PAYMENT_REQUEST = 'CUSTOMS_ENTRY_PAYMENT_REQUEST',
  // Broker paid for services
  CUSTOMS_ENTRY_PAYMENT_AUTHORIZED = 'CUSTOMS_ENTRY_PAYMENT_AUTHORIZED',
  // Customs Authority has held the goods for unclear reason
  CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_HOLD_GENERAL = 'CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_HOLD_GENERAL',
  // Non-Customs Authority has held the goods (eg. Trading Standards)
  CUSTOMS_ENTRY_NON_CUSTOMS_AUTHORITY_HOLD = 'CUSTOMS_ENTRY_NON_CUSTOMS_AUTHORITY_HOLD',
  // Customs hold to check classification
  CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_HOLD_CLASSIFICATION = 'CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_HOLD_CLASSIFICATION',
  // Customs hold to check valuation
  CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_HOLD_VALUATION = 'CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_HOLD_VALUATION',
  // Customs hold to check EORI/VAT
  CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_HOLD_SELLER_DETAILS = 'CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_HOLD_SELLER_DETAILS',
  // Customs authority to do document inspection
  CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_DOCUMENT_INSPECTION = 'CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_DOCUMENT_INSPECTION',
  // Customs authority to do physical inspection
  CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_PHYSICAL_INSPECTION = 'CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_PHYSICAL_INSPECTION',
  // Customs authority to do TS hold
  CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_TS_HOLD = 'CUSTOMS_ENTRY_CUSTOMS_AUTHORITY_TS_HOLD',
  // Broker hold to commercial doc issue
  CUSTOMS_ENTRY_BROKER_HOLD_COMMERCIAL_DOC_ISSUE = 'CUSTOMS_ENTRY_BROKER_HOLD_COMMERCIAL_DOC_ISSUE',
  // Broker hold to freight charges issue
  CUSTOMS_ENTRY_BROKER_HOLD_FREIGHT_CHARGES_ISSUE = 'CUSTOMS_ENTRY_BROKER_HOLD_FREIGHT_CHARGES_ISSUE',
  // Broker hold to check classification
  CUSTOMS_ENTRY_BROKER_HOLD_CLASSIFICATION = 'CUSTOMS_ENTRY_BROKER_HOLD_CLASSIFICATION',
  // Broker hold to check valuation
  CUSTOMS_ENTRY_BROKER_HOLD_VALUATION = 'CUSTOMS_ENTRY_BROKER_HOLD_VALUATION',
  // Broker hold to check EORI/VAT
  CUSTOMS_ENTRY_BROKER_HOLD_SELLER_DETAILS = 'CUSTOMS_ENTRY_BROKER_HOLD_SELLER_DETAILS',
  // Broker hold for other reasons
  CUSTOMS_ENTRY_BROKER_HOLD_OTHER = 'CUSTOMS_ENTRY_BROKER_HOLD_OTHER',
  // Customs Detained Pending Inspection at POD. Any other delay outside the above
  CUSTOMS_ENTRY_DELAY_OTHER = 'CUSTOMS_ENTRY_DELAY_OTHER',
  FINAL_CUSTOMS_ENTRY_DOCUMENT_UPLOADED = 'FINAL_CUSTOMS_ENTRY_DOCUMENT_UPLOADED',
  CUSTOMS_ENTRY_GOODS_SEIZED = 'CUSTOMS_ENTRY_GOODS_SEIZED',
  CUSTOMS_ENTRY_GOODS_RETURNED = 'CUSTOMS_ENTRY_GOODS_RETURNED',
  CUSTOMS_ENTRY_GOODS_DESTROYED = 'CUSTOMS_ENTRY_GOODS_DESTROYED',
  // Exception task created
  CUSTOMS_ENTRY_CUSTOMS_REQUESTED_AMENDMENTS_TASK_CREATED = 'CUSTOMS_ENTRY_CUSTOMS_REQUESTED_AMENDMENTS_TASK_CREATED',
  CUSTOMS_ENTRY_SELLER_REQUESTED_AMENDMENTS_TASK_CREATED = 'CUSTOMS_ENTRY_SELLER_REQUESTED_AMENDMENTS_TASK_CREATED',
  CUSTOMS_ENTRY_BROKER_ERROR_EXCEPTION_TASK_CREATED = 'CUSTOMS_ENTRY_BROKER_ERROR_EXCEPTION_TASK_CREATED',
  CUSTOMS_ENTRY_CUSTOMS_SYSTEM_ERROR_EXCEPTION_TASK_CREATED = 'CUSTOMS_ENTRY_CUSTOMS_SYSTEM_ERROR_EXCEPTION_TASK_CREATED',
  // Exception task completed
  CUSTOMS_ENTRY_CUSTOMS_REQUESTED_AMENDMENTS_TASK_COMPLETED = 'CUSTOMS_ENTRY_CUSTOMS_REQUESTED_AMENDMENTS_TASK_COMPLETED',
  CUSTOMS_ENTRY_SELLER_REQUESTED_AMENDMENTS_TASK_COMPLETED = 'CUSTOMS_ENTRY_SELLER_REQUESTED_AMENDMENTS_TASK_COMPLETED',
  CUSTOMS_ENTRY_BROKER_ERROR_EXCEPTION_TASK_COMPLETED = 'CUSTOMS_ENTRY_BROKER_ERROR_EXCEPTION_TASK_COMPLETED',
  CUSTOMS_ENTRY_CUSTOMS_SYSTEM_ERROR_EXCEPTION_TASK_COMPLETED = 'CUSTOMS_ENTRY_CUSTOMS_SYSTEM_ERROR_EXCEPTION_TASK_COMPLETED',

  // Documents Reviewed
  CUSTOMS_ENTRY_DOCUMENTS_REVIEWED = 'CUSTOMS_ENTRY_DOCUMENTS_REVIEWED',

  // New split invoice is added
  CUSTOMS_ENTRY_SPLIT_INVOICE_ADDED = 'CUSTOMS_ENTRY_SPLIT_INVOICE_ADDED',

  CUSTOMS_SELLER_DUTY_INVOICE_ISSUED = 'CUSTOMS_SELLER_DUTY_INVOICE_ISSUED',
  CUSTOMS_SELLER_DUTY_RECEIVED = 'CUSTOMS_SELLER_DUTY_RECEIVED',

  US_TYPE86_ENTRY_CREATED = 'US_TYPE86_ENTRY_CREATED',
  US_TYPE86_ENTRY_SUBMITTED = 'US_TYPE86_ENTRY_SUBMITTED',
  US_TYPE86_ENTRY_REJECTED = 'US_TYPE86_ENTRY_REJECTED',
  US_TYPE86_ENTRY_FILED = 'US_TYPE86_ENTRY_FILED',
  US_TYPE86_ENTRY_DELETED = 'US_TYPE86_ENTRY_DELETED',

  US_TYPE86_ENTRY_CBP_RELEASE = 'US_TYPE86_ENTRY_CBP_RELEASE',
  US_TYPE86_ENTRY_CBP_HOLD = 'US_TYPE86_ENTRY_CBP_HOLD',
  US_TYPE86_ENTRY_CBP_EXAM = 'US_TYPE86_ENTRY_CBP_EXAM',

  US_TYPE86_ENTRY_PGA_RELEASE = 'US_TYPE86_ENTRY_PGA_RELEASE',
  US_TYPE86_ENTRY_PGA_HOLD = 'US_TYPE86_ENTRY_PGA_HOLD',
  US_TYPE86_ENTRY_PGA_REJECTED = 'US_TYPE86_ENTRY_PGA_REJECTED',
  US_TYPE86_ENTRY_PGA_MAY_PROCEED_RESCINDED = 'US_TYPE86_ENTRY_PGA_MAY_PROCEED_RESCINDED',
  US_TYPE86_ENTRY_CLEARED_FOR_PICKUP = 'US_TYPE86_ENTRY_CLEARED_FOR_PICKUP',
  US_TYPE86_ENTRY_ONE_USG = 'US_TYPE86_ENTRY_ONE_USG',

  US_TYPE86_ENTRY_BILL_ON_FILE = 'US_TYPE86_ENTRY_BILL_ON_FILE',
  US_TYPE86_ENTRY_BILL_NOT_ON_FILE = 'US_TYPE86_ENTRY_BILL_NOT_ON_FILE',

  // IOR Onboarding (GbIorActivation,DeIorActivation,NlIorActivation,FrIorActivation) Milestones
  IOR_ACTIVATED = 'IOR_ACTIVATED',
  IOR_REJECTED = 'IOR_REJECTED',

  // WorkOrder Cancellation Milestones
  DELIVERY_ORDER_CANCELED = 'DELIVERY_ORDER_CANCELED',
  US_CONSUMPTION_ENTRY_CANCELED = 'US_CONSUMPTION_ENTRY_CANCELED',
  US_DRAWBACK_CLAIM_CANCELED = 'US_DRAWBACK_CLAIM_CANCELED',
  US_IN_BOND_CANCELED = 'US_IN_BOND_CANCELED',
  US_IOR_ACTIVATION_CANCELED = 'US_IOR_ACTIVATION_CANCELED',
  US_ISF_CANCELED = 'US_ISF_CANCELED',
  US_POST_SUMMARY_CORRECTION_CANCELED = 'US_POST_SUMMARY_CORRECTION_CANCELED',
  US_TYPE86_ENTRY_CANCELED = 'US_TYPE86_ENTRY_CANCELED',
  US_IOR_CONTINUOUS_BOND_REQUEST_CANCELED = 'US_IOR_CONTINUOUS_BOND_REQUEST_CANCELED',
  GB_CUSTOMS_ENTRY_CANCELED = 'GB_CUSTOMS_ENTRY_CANCELED',
  DE_CUSTOMS_ENTRY_CANCELED = 'DE_CUSTOMS_ENTRY_CANCELED',
  NL_CUSTOMS_ENTRY_CANCELED = 'NL_CUSTOMS_ENTRY_CANCELED',
  FR_CUSTOMS_ENTRY_CANCELED = 'FR_CUSTOMS_ENTRY_CANCELED',
  GB_IOR_ACTIVATION_CANCELED = 'GB_IOR_ACTIVATION_CANCELED',
  DE_IOR_ACTIVATION_CANCELED = 'DE_IOR_ACTIVATION_CANCELED',
  NL_IOR_ACTIVATION_CANCELED = 'NL_IOR_ACTIVATION_CANCELED',
  FR_IOR_ACTIVATION_CANCELED = 'FR_IOR_ACTIVATION_CANCELED',

  // WorkOrder UnCancellation Milestones
  DELIVERY_ORDER_UNCANCELED = 'DELIVERY_ORDER_UNCANCELED',
  US_CONSUMPTION_ENTRY_UNCANCELED = 'US_CONSUMPTION_ENTRY_UNCANCELED',
  US_DRAWBACK_CLAIM_UNCANCELED = 'US_DRAWBACK_CLAIM_UNCANCELED',
  US_IN_BOND_UNCANCELED = 'US_IN_BOND_UNCANCELED',
  US_IOR_ACTIVATION_UNCANCELED = 'US_IOR_ACTIVATION_UNCANCELED',
  US_ISF_UNCANCELED = 'US_ISF_UNCANCELED',
  US_POST_SUMMARY_CORRECTION_UNCANCELED = 'US_POST_SUMMARY_CORRECTION_UNCANCELED',
  US_TYPE86_ENTRY_UNCANCELED = 'US_TYPE86_ENTRY_UNCANCELED',
  US_IOR_CONTINUOUS_BOND_REQUEST_UNCANCELED = 'US_IOR_CONTINUOUS_BOND_REQUEST_UNCANCELED',
  GB_CUSTOMS_ENTRY_UNCANCELED = 'GB_CUSTOMS_ENTRY_UNCANCELED',
  DE_CUSTOMS_ENTRY_UNCANCELED = 'DE_CUSTOMS_ENTRY_UNCANCELED',
  NL_CUSTOMS_ENTRY_UNCANCELED = 'NL_CUSTOMS_ENTRY_UNCANCELED',
  FR_CUSTOMS_ENTRY_UNCANCELED = 'FR_CUSTOMS_ENTRY_UNCANCELED',
  GB_IOR_ACTIVATION_UNCANCELED = 'GB_IOR_ACTIVATION_UNCANCELED',
  DE_IOR_ACTIVATION_UNCANCELED = 'DE_IOR_ACTIVATION_UNCANCELED',
  NL_IOR_ACTIVATION_UNCANCELED = 'NL_IOR_ACTIVATION_UNCANCELED',
  FR_IOR_ACTIVATION_UNCANCELED = 'FR_IOR_ACTIVATION_UNCANCELED',

  // WorkOrder Flip Milestones
  US_CONSUMPTION_ENTRY_FLIPPED_TO_US_TYPE86_ENTRY = 'US_CONSUMPTION_ENTRY_FLIPPED_TO_US_TYPE86_ENTRY',
  US_TYPE86_ENTRY_FLIPPED_TO_US_CONSUMPTION_ENTRY = 'US_TYPE86_ENTRY_FLIPPED_TO_US_CONSUMPTION_ENTRY',

  // Data receive confirmation milestones
  ASN_DATA_RECEIVED = 'ASN_DATA_RECEIVED',
  COMMERCIAL_INVOICE_DATA_RECEIVED = 'COMMERCIAL_INVOICE_DATA_RECEIVED',

  // WorkOrder Cloning Milestones
  WORK_ORDER_REPLACED = 'WORK_ORDER_REPLACED',

  // Workorder Milestones for Customs exam in-gate and out-gate events
  CUSTOMS_EXAM_IN_GATE = 'CUSTOMS_EXAM_IN_GATE',
  CUSTOMS_EXAM_OUT_GATE = 'CUSTOMS_EXAM_OUT_GATE',

  // Workorder exam/hold status milestones
  CUSTOMS_ENTRY_ON_VESSEL = 'CUSTOMS_ENTRY_ON_VESSEL',
  CUSTOMS_ENTRY_PENDING_PICKUP_AT_TERMINAL = 'CUSTOMS_ENTRY_PENDING_PICKUP_AT_TERMINAL',
  CUSTOMS_ENTRY_AT_CFS_ON_HOLD = 'CUSTOMS_ENTRY_AT_CFS_ON_HOLD',
  CUSTOMS_ENTRY_TERMINAL_OUT_GATE = 'CUSTOMS_ENTRY_TERMINAL_OUT_GATE',
  CUSTOMS_ENTRY_AT_CFS_PENDING_EXAM_TRANSFER = 'CUSTOMS_ENTRY_AT_CFS_PENDING_EXAM_TRANSFER',
  CUSTOMS_ENTRY_CES_DEVANNING = 'CUSTOMS_ENTRY_CES_DEVANNING',
  CUSTOMS_ENTRY_AT_FUMIGATION_SITE = 'CUSTOMS_ENTRY_AT_FUMIGATION_SITE',
  CUSTOMS_ENTRY_CBP_HOLD_AT_CES = 'CUSTOMS_ENTRY_CBP_HOLD_AT_CES',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_DETAINED = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_DETAINED',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_RECONDITIONING = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_RECONDITIONING',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_PARTIAL_RELEASE = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_PARTIAL_RELEASE',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_DESTRUCTION = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_DESTRUCTION',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_EXPORT = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_EXPORT',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_COMPLETED = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_COMPLETED',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_RECONDITIONING_COMPLETED = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_RECONDITIONING_COMPLETED',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_DESTRUCTION_COMPLETED = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_DESTRUCTION_COMPLETED',
  CUSTOMS_ENTRY_SPECIAL_EXCEPTION_EXPORT_COMPLETED = 'CUSTOMS_ENTRY_SPECIAL_EXCEPTION_EXPORT_COMPLETED',

  // Customs Declaration Work Order Milestones
  CUSTOMS_DECLARATION_STARTED = 'CUSTOMS_DECLARATION_STARTED',
  CUSTOMS_DECLARATION_DUTY_CONFIRMATION_REQUESTED = 'CUSTOMS_DECLARATION_DUTY_CONFIRMATION_REQUESTED',
  CUSTOMS_DECLARATION_DUTY_CONFIRMED = 'CUSTOMS_DECLARATION_DUTY_CONFIRMED',
  CUSTOMS_DECLARATION_DUTY_DISPUTED = 'CUSTOMS_DECLARATION_DUTY_DISPUTED',
  CUSTOMS_DECLARATION_PAYMENT_REQUEST = 'CUSTOMS_DECLARATION_PAYMENT_REQUEST',
  CUSTOMS_DECLARATION_PAYMENT_AUTHORIZED = 'CUSTOMS_DECLARATION_PAYMENT_AUTHORIZED',
  CUSTOMS_DECLARATION_DOCUMENT_UPLOADED = 'CUSTOMS_DECLARATION_DOCUMENT_UPLOADED',
  CUSTOMS_DECLARATION_FILED = 'CUSTOMS_DECLARATION_FILED',
  CUSTOMS_DECLARATION_RELEASE = 'CUSTOMS_DECLARATION_RELEASE',
  CUSTOMS_DECLARATION_CUSTOMS_AUTHORITY_HOLD_GENERAL = 'CUSTOMS_DECLARATION_CUSTOMS_AUTHORITY_HOLD_GENERAL',
  CUSTOMS_DECLARATION_NON_CUSTOMS_AUTHORITY_HOLD = 'CUSTOMS_DECLARATION_NON_CUSTOMS_AUTHORITY_HOLD',
  CUSTOMS_DECLARATION_CUSTOMS_AUTHORITY_HOLD_CLASSIFICATION = 'CUSTOMS_DECLARATION_CUSTOMS_AUTHORITY_HOLD_CLASSIFICATION',
  CUSTOMS_DECLARATION_CUSTOMS_AUTHORITY_HOLD_VALUATION = 'CUSTOMS_DECLARATION_CUSTOMS_AUTHORITY_HOLD_VALUATION',
  CUSTOMS_DECLARATION_CUSTOMS_AUTHORITY_HOLD_SELLER_DETAILS = 'CUSTOMS_DECLARATION_CUSTOMS_AUTHORITY_HOLD_SELLER_DETAILS',
  CUSTOMS_DECLARATION_BROKER_HOLD_CLASSIFICATION = 'CUSTOMS_DECLARATION_BROKER_HOLD_CLASSIFICATION',
  CUSTOMS_DECLARATION_BROKER_HOLD_VALUATION = 'CUSTOMS_DECLARATION_BROKER_HOLD_VALUATION',
  CUSTOMS_DECLARATION_BROKER_HOLD_SELLER_DETAILS = 'CUSTOMS_DECLARATION_BROKER_HOLD_SELLER_DETAILS',
  CUSTOMS_DECLARATION_BROKER_HOLD_OTHER = 'CUSTOMS_DECLARATION_BROKER_HOLD_OTHER',
  CUSTOMS_DECLARATION_DELAY_OTHER = 'CUSTOMS_DECLARATION_DELAY_OTHER',
  CUSTOMS_DECLARATION_GOODS_SEIZED = 'CUSTOMS_DECLARATION_GOODS_SEIZED',
  CUSTOMS_DECLARATION_GOODS_RETURNED = 'CUSTOMS_DECLARATION_GOODS_RETURNED',
  CUSTOMS_DECLARATION_GOODS_DESTROYED = 'CUSTOMS_DECLARATION_GOODS_DESTROYED',
}

export const nonCancellableWorkOrderMilestones = [
  WorkOrderMilestoneName.US_CONSUMPTION_ENTRY_FILED,
  WorkOrderMilestoneName.CUSTOMS_ENTRY_FILED,
  WorkOrderMilestoneName.US_POST_SUMMARY_CORRECTION_SUBMITTED,
  WorkOrderMilestoneName.US_IN_BOND_SUBMITTED,
  WorkOrderMilestoneName.US_DRAWBACK_SUBMITTED,
  WorkOrderMilestoneName.US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_ATTEMPTED,
  WorkOrderMilestoneName.US_TYPE86_ENTRY_FILED,
  WorkOrderMilestoneName.DELIVERY_ORDER_ISSUED,
];
