import { partyFragments } from './party';
import { billingDetailsFields } from './billingDetails';
import { shipperWorkOrderConfirmationConfigurationsFields } from './workOrderConfirmationConfigurations';

// TODO add friendlyId after its added to the Shipper output schema
const shipperFragment = `
fragment shipperFields on Shipper {
  ...recordFields
  ...partyFields
  invitingParty {
    id
  }
  forwarders {
    edges {
      node {
         id
      }
    }
  }
  billingDetails {
    ...billingDetailsFields
  }
  status
  workOrderConfirmationConfigurations {
    ...shipperWorkOrderConfirmationConfigurationsFields
  }
  shipperExternallySourcedDetails {
    legalName
    externalReferenceId
    marketPlaceId
  }
}
`;

export const shipperFragments = `
${shipperFragment}
${partyFragments}
${billingDetailsFields}
${shipperWorkOrderConfirmationConfigurationsFields}
`;
