import { MutationFunctionOptions } from '@apollo/client';
import { IdVersion, RecordType } from '@xbcb/shared-types';
import { MutationType } from '@xbcb/shared-queries';
import { reportError } from '@xbcb/ui-utils';
import {
  createOptimisticUpdateFunction,
  optimisticCrudRecord,
} from '../optimisticResponse';
import { OptimisticUpdateParams } from '../interfaces';

export const mutateRecord = async <
  MutationVariables,
  Record extends IdVersion,
  RecordFields,
>({
  mutationType,
  mutation,
  mutationVariables,
  onlyUpdateCache = true,
  optimisticUpdateParams,
  overwriteOptimisticRecordFields,
  recordType,
}: {
  mutationType: MutationType;
  mutation: any;
  mutationVariables: MutationVariables;
  recordType: RecordType;
  /**
   * Optimistic updates only happen if you provide optimisticResponse in
   * the options. If onlyUpdateCache = true, Apollo Client will first update
   * the cache with the optimisticMutateRecord, and then uses the actual record
   * to update the cache again after it receives the response from the server.
   */
  onlyUpdateCache?: boolean;
  optimisticUpdateParams?: OptimisticUpdateParams<Record>;
  overwriteOptimisticRecordFields?: RecordFields;
}) => {
  try {
    const params: MutationFunctionOptions = {
      variables: mutationVariables,
    };
    if (optimisticUpdateParams) {
      if (!onlyUpdateCache) {
        const optimisticResponse = optimisticCrudRecord({
          crudType: mutationType,
          overwriteOptimisticRecordFields,
          recordType,
        });
        params.optimisticResponse = optimisticResponse;
      }
      params.update = createOptimisticUpdateFunction({
        crudType: mutationType,
        recordType,
        ...optimisticUpdateParams,
      });
    }
    const response = await mutation(params);
    return response;
  } catch (e) {
    reportError(e);
    throw e;
  }
};
